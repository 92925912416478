export type VectorBrandsVariant = keyof typeof vectorBrands;

export const vectorBrands = {
    admin: (
        <svg viewBox="0 0 903 175" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 175L78.75 -1.52588e-05H118.75L197 175H154.5L90.5 20.5H106.5L42.25 175H0.75ZM39.75 137.5L50.5 106.75H140.5L151.5 137.5H39.75Z" />
            <path d="M215.156 175V-1.52588e-05H294.656C313.656 -1.52588e-05 330.406 3.66665 344.906 11C359.406 18.1667 370.74 28.25 378.906 41.25C387.073 54.25 391.156 69.6667 391.156 87.5C391.156 105.167 387.073 120.583 378.906 133.75C370.74 146.75 359.406 156.917 344.906 164.25C330.406 171.417 313.656 175 294.656 175H215.156ZM255.656 141.75H292.656C304.323 141.75 314.406 139.583 322.906 135.25C331.573 130.75 338.24 124.417 342.906 116.25C347.74 108.083 350.156 98.5 350.156 87.5C350.156 76.3333 347.74 66.75 342.906 58.75C338.24 50.5833 331.573 44.3333 322.906 40C314.406 35.5 304.323 33.25 292.656 33.25H255.656V141.75Z" />
            <path d="M421.699 175V-1.52588e-05H455.199L529.699 123.5H511.949L585.199 -1.52588e-05H618.449L618.949 175H580.949L580.699 58.25H587.699L529.199 156.5H510.949L451.199 58.25H459.699V175H421.699Z" />
            <path d="M660.469 175V-1.52588e-05H700.969V175H660.469Z" />
            <path d="M742.5 175V-1.52588e-05H776L879.25 126H863V-1.52588e-05H903V175H869.75L766.25 49H782.5V175H742.5Z" />
        </svg>
    ),
    adminshort: (
        <svg viewBox="0 0 903 175" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 175L78.75 -1.52588e-05H118.75L197 175H154.5L90.5 20.5H106.5L42.25 175H0.75ZM39.75 137.5L50.5 106.75H140.5L151.5 137.5H39.75Z" />
            <path d="M215.156 175V-1.52588e-05H294.656C313.656 -1.52588e-05 330.406 3.66665 344.906 11C359.406 18.1667 370.74 28.25 378.906 41.25C387.073 54.25 391.156 69.6667 391.156 87.5C391.156 105.167 387.073 120.583 378.906 133.75C370.74 146.75 359.406 156.917 344.906 164.25C330.406 171.417 313.656 175 294.656 175H215.156ZM255.656 141.75H292.656C304.323 141.75 314.406 139.583 322.906 135.25C331.573 130.75 338.24 124.417 342.906 116.25C347.74 108.083 350.156 98.5 350.156 87.5C350.156 76.3333 347.74 66.75 342.906 58.75C338.24 50.5833 331.573 44.3333 322.906 40C314.406 35.5 304.323 33.25 292.656 33.25H255.656V141.75Z" />
            <path d="M421.699 175V-1.52588e-05H455.199L529.699 123.5H511.949L585.199 -1.52588e-05H618.449L618.949 175H580.949L580.699 58.25H587.699L529.199 156.5H510.949L451.199 58.25H459.699V175H421.699Z" />
            <path d="M660.469 175V-1.52588e-05H700.969V175H660.469Z" />
            <path d="M742.5 175V-1.52588e-05H776L879.25 126H863V-1.52588e-05H903V175H869.75L766.25 49H782.5V175H742.5Z" />
        </svg>
    ),
    aua: (
        <svg viewBox="0 0 76 23" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 22.8062L10.0787 0.193848H15.2472L25.3581 22.8062H19.8666L11.5969 2.84272H13.6643L5.36236 22.8062H0ZM5.03933 17.9607L6.42837 13.9874H18.0576L19.4789 17.9607H5.03933Z"/>
            <path
                d="M37.5983 23C34.3895 23 31.8806 22.1063 30.0716 20.3188C28.2626 18.5314 27.3582 15.9794 27.3582 12.6629V0H32.5913V12.4691C32.5913 14.6227 33.0328 16.1732 33.9157 17.1208C34.7987 18.0684 36.037 18.5421 37.6306 18.5421C39.2243 18.5421 40.4626 18.0684 41.3455 17.1208C42.2285 16.1732 42.67 14.6227 42.67 12.4691V0H47.8385V12.6629C47.8385 15.9794 46.934 18.5314 45.125 20.3188C43.316 22.1063 40.8071 23 37.5983 23Z"/>
            <path
                d="M49.8385 22.8062L59.9171 0.193848H65.0857L75.1966 22.8062H69.705L61.4354 2.84272H63.5028L55.2008 22.8062H49.8385ZM54.8778 17.9607L56.2668 13.9874H67.8961L69.3174 17.9607H54.8778Z"/>
        </svg>
    ),
    telbook: (
        <svg viewBox="0 0 119 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.537109 17.7823V0.0822754H2.33711V17.7823H0.537109ZM3.23711 17.7823V0.0822754H5.93711V17.7823H3.23711ZM8.63711 17.7823V0.0822754H9.53711V17.7823H8.63711Z"
            />
            <path
                d="M10.4395 17.7823V0.0822754H11.3395V17.7823H10.4395ZM14.0395 17.7823V0.0822754H15.8395V17.7823H14.0395ZM16.7395 17.7823V0.0822754H17.6395V17.7823H16.7395Z"
            />
            <path
                d="M20.3418 17.7823V0.0822754H21.2418V17.7823H20.3418ZM23.9418 17.7823V0.0822754H25.7418V17.7823H23.9418ZM26.6418 17.7823V0.0822754H29.3418V17.7823H26.6418Z"
            />
            <path
                d="M30.2441 17.7823V0.0822754H31.1441V17.7823H30.2441ZM33.8441 17.7823V0.0822754H34.7441V17.7823H33.8441ZM35.6441 17.7823V0.0822754H37.4441V17.7823H35.6441Z"
            />
            <path
                d="M40.1465 17.7823V0.0822754H41.0465V17.7823H40.1465ZM43.7465 17.7823V0.0822754H46.4465V17.7823H43.7465ZM47.3465 17.7823V0.0822754H49.1465V17.7823H47.3465Z"
            />
            <path
                d="M50.0488 17.7823V0.0822754H50.9488V17.7823H50.0488ZM53.6488 17.7823V0.0822754H56.3488V17.7823H53.6488ZM57.2488 17.7823V0.0822754H59.0488V17.7823H57.2488Z"
            />
            <path
                d="M59.9512 17.7823V0.0822754H60.8512V17.7823H59.9512ZM61.7512 17.7823V0.0822754H63.5512V17.7823H61.7512ZM66.2512 17.7823V0.0822754H68.9512V17.7823H66.2512Z"
            />
            <path
                d="M69.8535 17.7823V0.0822754H70.7535V17.7823H69.8535ZM72.5535 17.7823V0.0822754H74.3535V17.7823H72.5535ZM76.1535 17.7823V0.0822754H78.8535V17.7823H76.1535Z"
            />
            <path
                d="M79.7559 17.7823V0.0822754H81.5559V17.7823H79.7559ZM84.2559 17.7823V0.0822754H85.1559V17.7823H84.2559ZM87.8559 17.7823V0.0822754H88.7559V17.7823H87.8559Z"
            />
            <path
                d="M89.6582 17.7823V0.0822754H90.5582V17.7823H89.6582ZM91.4582 17.7823V0.0822754H94.1582V17.7823H91.4582ZM96.8582 17.7823V0.0822754H98.6582V17.7823H96.8582Z"
            />
            <path
                d="M99.5605 17.7823V0.0822754H100.461V17.7823H99.5605ZM103.161 17.7823V0.0822754H104.961V17.7823H103.161ZM107.661 17.7823V0.0822754H108.561V17.7823H107.661Z"
            />
            <path
                d="M109.463 17.7823V0.0822754H110.363V17.7823H109.463ZM113.063 17.7823V0.0822754H115.763V17.7823H113.063ZM116.663 17.7823V0.0822754H118.463V17.7823H116.663Z"
            />
            <path d="M3.27311 29.7823V22.9663H0.585109V21.3823H7.90511V22.9663H5.21711V29.7823H3.27311Z" fill="white"/>
            <path
                d="M12.7791 24.7663H16.8231V26.2783H12.7791V24.7663ZM12.9231 28.2223H17.4951V29.7823H10.9911V21.3823H17.3391V22.9423H12.9231V28.2223Z"
            />
            <path d="M21.0819 29.7823V21.3823H23.0259V28.1983H27.2379V29.7823H21.0819Z" fill="white"/>
            <path
                d="M30.364 29.7823V21.3823H34.468C35.524 21.3823 36.316 21.5823 36.844 21.9823C37.38 22.3823 37.648 22.9103 37.648 23.5663C37.648 24.0063 37.54 24.3903 37.324 24.7183C37.108 25.0383 36.812 25.2863 36.436 25.4623C36.06 25.6383 35.628 25.7263 35.14 25.7263L35.368 25.2343C35.896 25.2343 36.364 25.3223 36.772 25.4983C37.18 25.6663 37.496 25.9183 37.72 26.2543C37.952 26.5903 38.068 27.0023 38.068 27.4903C38.068 28.2103 37.784 28.7743 37.216 29.1823C36.648 29.5823 35.812 29.7823 34.708 29.7823H30.364ZM32.296 28.3183H34.564C35.068 28.3183 35.448 28.2383 35.704 28.0783C35.968 27.9103 36.1 27.6463 36.1 27.2863C36.1 26.9343 35.968 26.6743 35.704 26.5063C35.448 26.3303 35.068 26.2423 34.564 26.2423H32.152V24.8263H34.228C34.7 24.8263 35.06 24.7463 35.308 24.5863C35.564 24.4183 35.692 24.1663 35.692 23.8303C35.692 23.5023 35.564 23.2583 35.308 23.0983C35.06 22.9303 34.7 22.8463 34.228 22.8463H32.296V28.3183Z"
            />
            <path
                d="M45.6598 29.9263C44.9958 29.9263 44.3798 29.8183 43.8118 29.6023C43.2518 29.3863 42.7638 29.0823 42.3478 28.6903C41.9398 28.2983 41.6198 27.8383 41.3878 27.3103C41.1638 26.7823 41.0518 26.2063 41.0518 25.5823C41.0518 24.9583 41.1638 24.3823 41.3878 23.8543C41.6198 23.3263 41.9438 22.8663 42.3598 22.4743C42.7758 22.0823 43.2638 21.7783 43.8238 21.5623C44.3838 21.3463 44.9918 21.2383 45.6478 21.2383C46.3118 21.2383 46.9198 21.3463 47.4718 21.5623C48.0318 21.7783 48.5158 22.0823 48.9238 22.4743C49.3398 22.8663 49.6638 23.3263 49.8958 23.8543C50.1278 24.3743 50.2438 24.9503 50.2438 25.5823C50.2438 26.2063 50.1278 26.7863 49.8958 27.3223C49.6638 27.8503 49.3398 28.3103 48.9238 28.7023C48.5158 29.0863 48.0318 29.3863 47.4718 29.6023C46.9198 29.8183 46.3158 29.9263 45.6598 29.9263ZM45.6478 28.2703C46.0238 28.2703 46.3678 28.2063 46.6798 28.0783C46.9998 27.9503 47.2798 27.7663 47.5198 27.5263C47.7598 27.2863 47.9438 27.0023 48.0718 26.6743C48.2078 26.3463 48.2758 25.9823 48.2758 25.5823C48.2758 25.1823 48.2078 24.8183 48.0718 24.4903C47.9438 24.1623 47.7598 23.8783 47.5198 23.6383C47.2878 23.3983 47.0118 23.2143 46.6918 23.0863C46.3718 22.9583 46.0238 22.8943 45.6478 22.8943C45.2718 22.8943 44.9238 22.9583 44.6038 23.0863C44.2918 23.2143 44.0158 23.3983 43.7758 23.6383C43.5358 23.8783 43.3478 24.1623 43.2118 24.4903C43.0838 24.8183 43.0198 25.1823 43.0198 25.5823C43.0198 25.9743 43.0838 26.3383 43.2118 26.6743C43.3478 27.0023 43.5318 27.2863 43.7638 27.5263C44.0038 27.7663 44.2838 27.9503 44.6038 28.0783C44.9238 28.2063 45.2718 28.2703 45.6478 28.2703Z"
            />
            <path
                d="M57.8248 29.9263C57.1608 29.9263 56.5448 29.8183 55.9768 29.6023C55.4168 29.3863 54.9288 29.0823 54.5128 28.6903C54.1048 28.2983 53.7848 27.8383 53.5528 27.3103C53.3288 26.7823 53.2168 26.2063 53.2168 25.5823C53.2168 24.9583 53.3288 24.3823 53.5528 23.8543C53.7848 23.3263 54.1088 22.8663 54.5248 22.4743C54.9408 22.0823 55.4288 21.7783 55.9888 21.5623C56.5488 21.3463 57.1568 21.2383 57.8128 21.2383C58.4768 21.2383 59.0848 21.3463 59.6368 21.5623C60.1968 21.7783 60.6808 22.0823 61.0888 22.4743C61.5048 22.8663 61.8288 23.3263 62.0608 23.8543C62.2928 24.3743 62.4088 24.9503 62.4088 25.5823C62.4088 26.2063 62.2928 26.7863 62.0608 27.3223C61.8288 27.8503 61.5048 28.3103 61.0888 28.7023C60.6808 29.0863 60.1968 29.3863 59.6368 29.6023C59.0848 29.8183 58.4808 29.9263 57.8248 29.9263ZM57.8128 28.2703C58.1888 28.2703 58.5328 28.2063 58.8448 28.0783C59.1648 27.9503 59.4448 27.7663 59.6848 27.5263C59.9248 27.2863 60.1088 27.0023 60.2368 26.6743C60.3728 26.3463 60.4408 25.9823 60.4408 25.5823C60.4408 25.1823 60.3728 24.8183 60.2368 24.4903C60.1088 24.1623 59.9248 23.8783 59.6848 23.6383C59.4528 23.3983 59.1768 23.2143 58.8568 23.0863C58.5368 22.9583 58.1888 22.8943 57.8128 22.8943C57.4368 22.8943 57.0888 22.9583 56.7688 23.0863C56.4568 23.2143 56.1808 23.3983 55.9408 23.6383C55.7008 23.8783 55.5128 24.1623 55.3768 24.4903C55.2488 24.8183 55.1848 25.1823 55.1848 25.5823C55.1848 25.9743 55.2488 26.3383 55.3768 26.6743C55.5128 27.0023 55.6968 27.2863 55.9288 27.5263C56.1688 27.7663 56.4488 27.9503 56.7688 28.0783C57.0888 28.2063 57.4368 28.2703 57.8128 28.2703Z"
            />
            <path
                d="M67.6498 27.8623L67.5418 25.6183L71.5618 21.3823H73.7218L70.0978 25.2823L69.0178 26.4343L67.6498 27.8623ZM65.9098 29.7823V21.3823H67.8418V29.7823H65.9098ZM71.6578 29.7823L68.6698 26.1223L69.9418 24.7423L73.9258 29.7823H71.6578Z"
            />
            <path
                d="M77.4086 29.8783C77.0886 29.8783 76.8126 29.7703 76.5806 29.5543C76.3566 29.3303 76.2446 29.0463 76.2446 28.7023C76.2446 28.3583 76.3566 28.0823 76.5806 27.8743C76.8126 27.6583 77.0886 27.5503 77.4086 27.5503C77.7366 27.5503 78.0126 27.6583 78.2366 27.8743C78.4606 28.0823 78.5726 28.3583 78.5726 28.7023C78.5726 29.0463 78.4606 29.3303 78.2366 29.5543C78.0126 29.7703 77.7366 29.8783 77.4086 29.8783Z"
            />
            <path d="M82.0133 29.7823V21.3823H83.9573V29.7823H82.0133Z" fill="white"/>
            <path
                d="M87.9908 29.7823V21.3823H89.5988L94.5548 27.4303H93.7748V21.3823H95.6948V29.7823H94.0988L89.1308 23.7343H89.9108V29.7823H87.9908Z"
            />
            <path
                d="M101.522 25.1623H105.554V26.7223H101.522V25.1623ZM101.666 29.7823H99.7222V21.3823H106.07V22.9423H101.666V29.7823Z"
            />
            <path
                d="M113.424 29.9263C112.76 29.9263 112.144 29.8183 111.576 29.6023C111.016 29.3863 110.528 29.0823 110.112 28.6903C109.704 28.2983 109.384 27.8383 109.152 27.3103C108.928 26.7823 108.816 26.2063 108.816 25.5823C108.816 24.9583 108.928 24.3823 109.152 23.8543C109.384 23.3263 109.708 22.8663 110.124 22.4743C110.54 22.0823 111.028 21.7783 111.588 21.5623C112.148 21.3463 112.756 21.2383 113.412 21.2383C114.076 21.2383 114.684 21.3463 115.236 21.5623C115.796 21.7783 116.28 22.0823 116.688 22.4743C117.104 22.8663 117.428 23.3263 117.66 23.8543C117.892 24.3743 118.008 24.9503 118.008 25.5823C118.008 26.2063 117.892 26.7863 117.66 27.3223C117.428 27.8503 117.104 28.3103 116.688 28.7023C116.28 29.0863 115.796 29.3863 115.236 29.6023C114.684 29.8183 114.08 29.9263 113.424 29.9263ZM113.412 28.2703C113.788 28.2703 114.132 28.2063 114.444 28.0783C114.764 27.9503 115.044 27.7663 115.284 27.5263C115.524 27.2863 115.708 27.0023 115.836 26.6743C115.972 26.3463 116.04 25.9823 116.04 25.5823C116.04 25.1823 115.972 24.8183 115.836 24.4903C115.708 24.1623 115.524 23.8783 115.284 23.6383C115.052 23.3983 114.776 23.2143 114.456 23.0863C114.136 22.9583 113.788 22.8943 113.412 22.8943C113.036 22.8943 112.688 22.9583 112.368 23.0863C112.056 23.2143 111.78 23.3983 111.54 23.6383C111.3 23.8783 111.112 24.1623 110.976 24.4903C110.848 24.8183 110.784 25.1823 110.784 25.5823C110.784 25.9743 110.848 26.3383 110.976 26.6743C111.112 27.0023 111.296 27.2863 111.528 27.5263C111.768 27.7663 112.048 27.9503 112.368 28.0783C112.688 28.2063 113.036 28.2703 113.412 28.2703Z"
            />
        </svg>
    ),
    telbookshort: (
        <svg viewBox="0 0 42 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.705078 11.4264V0.806396H1.78508V11.4264H0.705078ZM2.32508 11.4264V0.806396H3.94508V11.4264H2.32508ZM5.56508 11.4264V0.806396H6.10508V11.4264H5.56508ZM3.38708 13.4064H2.21708V12.9924H5.09708V13.4064H3.87308V17.1684H3.38708V13.4064Z"
            />
            <path
                d="M6.64648 11.4264V0.806396H7.18648V11.4264H6.64648ZM8.80648 11.4264V0.806396H9.88648V11.4264H8.80648ZM10.4265 11.4264V0.806396H10.9665V11.4264H10.4265ZM8.33848 12.9924H10.8945V13.4064H8.77048V14.7744H10.5345V15.2064H8.77048V16.7544H10.8765V17.1684H8.33848V12.9924Z"
            />
            <path
                d="M12.5879 11.4264V0.806396H13.1279V11.4264H12.5879ZM14.7479 11.4264V0.806396H15.8279V11.4264H14.7479ZM16.3679 11.4264V0.806396H17.9879V11.4264H16.3679ZM14.3339 12.9924H14.8739V13.0464C14.8499 13.0704 14.8319 13.1004 14.8199 13.1364C14.8199 13.1604 14.8199 13.2144 14.8199 13.2984V16.7724H16.8179V17.1684H14.3339V12.9924Z"
            />
            <path
                d="M18.5293 11.4264V0.806396H19.0693V11.4264H18.5293ZM20.6893 11.4264V0.806396H21.2293V11.4264H20.6893ZM21.7693 11.4264V0.806396H22.8493V11.4264H21.7693ZM20.1493 12.9924H21.3373C21.7933 12.9924 22.1353 13.0824 22.3633 13.2624C22.6033 13.4424 22.7233 13.6944 22.7233 14.0184C22.7233 14.2224 22.6693 14.4084 22.5613 14.5764C22.4413 14.7444 22.2853 14.8644 22.0933 14.9364C22.3333 15.0204 22.5253 15.1584 22.6693 15.3504C22.8133 15.5544 22.8853 15.7704 22.8853 15.9984C22.8853 16.3584 22.7533 16.6464 22.4893 16.8624C22.2373 17.0664 21.8473 17.1684 21.3193 17.1684H20.1493V12.9924ZM21.2653 14.7204C21.9253 14.7204 22.2553 14.4984 22.2553 14.0544C22.2553 13.8624 22.1773 13.7064 22.0213 13.5864C21.8773 13.4544 21.6493 13.3884 21.3373 13.3884H20.5993V14.7204H21.2653ZM21.4093 16.7544C22.0573 16.7544 22.3813 16.4904 22.3813 15.9624C22.3813 15.3984 22.0153 15.1164 21.2833 15.1164H20.5993V16.7544H21.4093Z"
            />
            <path
                d="M24.4707 11.4264V0.806396H25.0107V11.4264H24.4707ZM26.6307 11.4264V0.806396H28.2507V11.4264H26.6307ZM28.7907 11.4264V0.806396H29.8707V11.4264H28.7907ZM27.4407 17.2404C26.9607 17.2404 26.5947 17.0544 26.3427 16.6824C26.0787 16.3104 25.9467 15.7824 25.9467 15.0984C25.9467 14.4384 26.0787 13.9164 26.3427 13.5324C26.6067 13.1604 26.9727 12.9744 27.4407 12.9744C27.9087 12.9744 28.2747 13.1604 28.5387 13.5324C28.8027 13.9164 28.9347 14.4384 28.9347 15.0984C28.9347 15.7824 28.8027 16.3104 28.5387 16.6824C28.2867 17.0544 27.9207 17.2404 27.4407 17.2404ZM27.4407 16.8264C27.7647 16.8264 28.0107 16.6824 28.1787 16.3944C28.3587 16.0944 28.4487 15.6624 28.4487 15.0984C28.4487 14.5704 28.3587 14.1504 28.1787 13.8384C27.9987 13.5384 27.7527 13.3884 27.4407 13.3884C27.1287 13.3884 26.8827 13.5384 26.7027 13.8384C26.5227 14.1504 26.4327 14.5704 26.4327 15.0984C26.4327 15.6624 26.5167 16.0944 26.6847 16.3944C26.8647 16.6824 27.1167 16.8264 27.4407 16.8264Z"
            />
            <path
                d="M30.4121 11.4264V0.806396H30.9521V11.4264H30.4121ZM32.5721 11.4264V0.806396H34.1921V11.4264H32.5721ZM34.7321 11.4264V0.806396H35.8121V11.4264H34.7321ZM33.3821 17.2404C32.9021 17.2404 32.5361 17.0544 32.2841 16.6824C32.0201 16.3104 31.8881 15.7824 31.8881 15.0984C31.8881 14.4384 32.0201 13.9164 32.2841 13.5324C32.5481 13.1604 32.9141 12.9744 33.3821 12.9744C33.8501 12.9744 34.2161 13.1604 34.4801 13.5324C34.7441 13.9164 34.8761 14.4384 34.8761 15.0984C34.8761 15.7824 34.7441 16.3104 34.4801 16.6824C34.2281 17.0544 33.8621 17.2404 33.3821 17.2404ZM33.3821 16.8264C33.7061 16.8264 33.9521 16.6824 34.1201 16.3944C34.3001 16.0944 34.3901 15.6624 34.3901 15.0984C34.3901 14.5704 34.3001 14.1504 34.1201 13.8384C33.9401 13.5384 33.6941 13.3884 33.3821 13.3884C33.0701 13.3884 32.8241 13.5384 32.6441 13.8384C32.4641 14.1504 32.3741 14.5704 32.3741 15.0984C32.3741 15.6624 32.4581 16.0944 32.6261 16.3944C32.8061 16.6824 33.0581 16.8264 33.3821 16.8264Z"
            />
            <path
                d="M36.3535 11.4264V0.806396H36.8935V11.4264H36.3535ZM37.4335 11.4264V0.806396H38.5135V11.4264H37.4335ZM40.1335 11.4264V0.806396H41.7535V11.4264H40.1335ZM38.7655 15.0804L38.4235 15.4404V17.1684H37.9375V12.9924H38.4955V13.0464C38.4715 13.0704 38.4535 13.1004 38.4415 13.1364C38.4295 13.1604 38.4235 13.2144 38.4235 13.2984V14.9184L40.1695 12.9744C40.2775 12.9864 40.3915 12.9924 40.5115 12.9924H40.6915L39.1075 14.7924L40.7995 17.1684L40.2055 17.1864L38.7655 15.0804Z"
            />
        </svg>
    ),
    polskidev: (
        <svg fill="none" viewBox="0 0 180 21" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 20.5H5.71429V11.9286H14.2857V9.07143H17.1429V3.35714H14.2857V0.5H0V20.5ZM5.71429 9.07143V3.35714H11.4286V9.07143H5.71429Z"/>
            <path
                d="M22.8571 20.5H34.2857V17.6429H37.1429V3.35714H34.2857V0.5H22.8571V3.35714H20V17.6429H22.8571V20.5ZM25.7143 17.6429V3.35714H31.4286V17.6429H25.7143Z"/>
            <path d="M45.7143 0.5H40V20.5H57.1429V17.6429H45.7143V0.5Z"/>
            <path
                d="M62.8571 20.5H74.2857V17.6429H77.1429V11.9286H74.2857V9.07143H65.7143V3.35714H71.4286V6.21429H77.1429V3.35714H74.2857V0.5H62.8571V3.35714H60V9.07143H62.8571V11.9286H71.4286V17.6429H65.7143V14.7857H60V17.6429H62.8571V20.5Z"/>
            <path
                d="M80 20.5H85.7143V14.7857H88.5714V17.6429H91.4286V20.5H97.1429V17.6429H94.2857V14.7857H91.4286V11.9286H88.5714V9.07143H91.4286V6.21429H94.2857V3.35714H97.1429V0.5H91.4286V3.35714H88.5714V6.21429H85.7143V0.5H80V20.5Z"/>
            <path
                d="M111.429 0.5H100V3.35714H102.857V17.6429H100V20.5H111.429V17.6429H108.571V3.35714H111.429V0.5Z"/>
            <path d="M114.286 20.5H120V14.7857H114.286V20.5Z"/>
            <path
                d="M122.857 20.5H134.286V17.6429H137.143V14.7857H140V6.21429H137.143V3.35714H134.286V0.5H122.857V20.5ZM128.571 17.6429V3.35714H131.429V6.21429H134.286V14.7857H131.429V17.6429H128.571Z"
                fill="white"/>
            <path
                d="M160 0.5H142.857V20.5H160V17.6429H148.571V11.9286H154.286V9.07143H148.571V3.35714H160V0.5Z"/>
            <path
                d="M168.571 20.5H174.286V17.6429H177.143V14.7857H180V0.5H174.286V14.7857H168.571V0.5H162.857V14.7857H165.714V17.6429H168.571V20.5Z"/>
        </svg>
    ),
    polskidevshort: (
        <svg width="71" height="20" viewBox="0 0 71 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.8514 19.9501L10.8359 16.8801L6.23101 16.9034L6.20772 12.2985L4.67276 12.3063L4.665 10.7713L1.59507 10.7869L1.60284 12.3218L0.0678726 12.3296L0.106689 20.0044L10.8514 19.9501ZM4.69605 16.9112L1.62613 16.9267L1.6106 13.8568L4.68052 13.8413L4.69605 16.9112Z"
                fill="white"/>
            <path
                d="M0.0368192 6.18973L0.0523459 9.25965L10.7971 9.20531L10.7505 -0.00446652L9.21554 0.00329684L9.2466 6.14315L0.0368192 6.18973Z"
                fill="white"/>
            <path
                d="M13.8514 20H25.28V17.1429H28.1371V14.2857H30.9943V5.71429H28.1371V2.85714H25.28V0H13.8514V20ZM19.5657 17.1429V2.85714H22.4229V5.71429H25.28V14.2857H22.4229V17.1429H19.5657Z"
                fill="white"/>
            <path
                d="M50.9943 0H33.8514V20H50.9943V17.1429H39.5657V11.4286H45.28V8.57143H39.5657V2.85714H50.9943V0Z"
                fill="white"/>
            <path
                d="M59.5657 20H65.28V17.1429H68.1371V14.2857H70.9943V0H65.28V14.2857H59.5657V0H53.8514V14.2857H56.7086V17.1429H59.5657V20Z"
                fill="white"/>
        </svg>
    ),
    uxu: (
        <svg fill="none"
             viewBox="0 0 133 40"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.7715 40C30.3682 40 37.2029 33.4454 37.2029 21.7367V0H26.2785V21.4006C26.2785 28.1232 23.4774 30.8123 18.8836 30.8123C14.2337 30.8123 11.4326 28.1232 11.4326 21.4006V0H0.340149V21.7367C0.340149 33.4454 7.17488 40 18.7715 40Z"
            />
            <path
                d="M87.3075 39.2157L72.7417 18.9356L86.4111 0H74.4223L66.6912 11.3165L58.8481 0H46.3551L60.0246 19.3838L45.7389 39.2157H58.3999L66.4111 27.0588L74.5344 39.2157H87.3075Z"/>
            <path
                d="M114.228 40C125.825 40 132.66 33.4454 132.66 21.7367V0H121.735V21.4006C121.735 28.1232 118.934 30.8123 114.34 30.8123C109.691 30.8123 106.89 28.1232 106.89 21.4006V0H95.7971V21.7367C95.7971 33.4454 102.632 40 114.228 40Z"
            />
        </svg>
    ),
    wTrasie: (
        <svg fill="none"
             viewBox="0 0 141 20"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.6633 19.6218H26.1278V3.96846H32.301V0.330704H15.4902V3.96846H21.6633V19.6218Z"/>
            <path
                d="M56.4746 19.6218L52.1478 13.4211C54.6557 12.3463 56.1163 10.2243 56.1163 7.33063C56.1163 3.0039 52.8919 0.330704 47.7384 0.330704H39.3881V19.6218H43.8526V14.2479H47.9589L51.6793 19.6218H56.4746ZM51.5967 7.33063C51.5967 9.42509 50.2187 10.6928 47.4904 10.6928H43.8526V3.96846H47.4904C50.2187 3.96846 51.5967 5.2086 51.5967 7.33063Z"/>
            <path
                d="M95.6564 19.9525C101.168 19.9525 103.841 17.1967 103.841 13.9723C103.841 6.88969 92.625 9.34242 92.625 5.78734C92.625 4.57475 93.6446 3.58264 96.2903 3.58264C97.9989 3.58264 99.8454 4.0787 101.637 5.09837L103.015 1.70864C101.223 0.578733 98.743 0 96.3178 0C90.8336 0 88.188 2.72832 88.188 6.00781C88.188 13.1731 99.4044 10.6928 99.4044 14.303C99.4044 15.488 98.3296 16.3699 95.684 16.3699C93.3691 16.3699 90.9439 15.5431 89.3179 14.3581L87.8022 17.7203C89.5108 19.0431 92.5974 19.9525 95.6564 19.9525Z"/>
            <path d="M111.577 19.6218H116.042V0.330704H111.577V19.6218Z"/>
            <path
                d="M129.742 16.0392V11.5747H138.698V8.10227H129.742V3.91334H139.883V0.330704H125.305V19.6218H140.242V16.0392H129.742Z"/>
            <path
                d="M0.758484 3.02687L9.49155 5.89681L0.758484 8.71392V11.355L9.42112 14.2601L0.758484 17.042V20L13.0834 15.9504V12.9044L4.73766 10.1049L13.0834 7.3934V4.32978L0.758484 0.297787V3.02687Z"/>
            <path d="M72.4113 0.483809H73.5595L83.5113 19.6218H78.7547L72.4113 0.483809Z"/>
            <path d="M72.794 0.483809H71.6457L61.694 19.6218H66.4506L72.794 0.483809Z"/>
            <path d="M72.0285 15.7942H73.5595L74.3251 19.6218H71.263L72.0285 15.7942Z"/>
            <path d="M72.4113 10.4356H73.1768L73.5595 14.2632H72.0285L72.4113 10.4356Z"/>
            <path d="M72.794 5.07693L73.1768 8.90454H72.4113L72.794 5.07693Z"/>
        </svg>
    ),
    wTrasieShort: (
        <svg fill="none" viewBox="0 0 23 21" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3086 0.93103H12.4569L22.4087 20.0691H17.6521L11.3086 0.93103Z"/>
            <path d="M11.6914 0.93103H10.5431L0.591309 20.0691H5.34793L11.6914 0.93103Z"/>
            <path d="M10.9259 16.2414H12.4569L13.2224 20.0691H10.1603L10.9259 16.2414Z"/>
            <path d="M11.3086 10.8828H12.0741L12.4569 14.7104H10.9259L11.3086 10.8828Z"/>
            <path d="M11.6914 5.52416L12.0741 9.35176H11.3086L11.6914 5.52416Z"/>
        </svg>
    ),
} as const;
