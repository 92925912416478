import type {ReactElement} from 'react';
import {useState, useEffect} from 'react';
import {Feedback} from '../../../organisms/feedback';
import {MenuContainer, MenuPopup} from '../../../molecules/menu';
import type {HeaderRightComponentsProps} from './types';
import {deleteCookie} from 'cookies-next'
import {useSiteConfig} from "../../../../hooks/useSiteConfig";
import styles from './headerRightComponents.module.scss';
import {CookiesName} from "@uxu/utils/common/enums";
import {useRouter} from "next/router";

export function HeaderRightComponents({ switchModalButtonText, modalAlignment }: HeaderRightComponentsProps): ReactElement {
    const {config} = useSiteConfig();
    const router  = useRouter();
    const [isAuth, setIsAuth] = useState(config.client?.auth.isAuth);

    function logOut() {
        setIsAuth(false);
        deleteCookie(CookiesName.AuthToken);
        router.push("/")
    }

    function login() {
        const redirectURI = encodeURIComponent(`${config.site.canonicalUrl}${router.pathname}`);
        router.push(`/api/v1/auth/token?redirect_uri=${redirectURI}`)
    }

    return (
        <>
            <Feedback
                modalAlignment={modalAlignment}
                onFeedbackSubmit={async () => {
                    return new Promise(resolve => {
                        setTimeout(() => {
                            resolve({success: true});
                        }, 3000);
                    });
                }}
                switchModalButtonText={switchModalButtonText}
            />
            {config.site.authEnabled && (isAuth ? (
                <MenuContainer>
                    <button type="button" className="btn primary">konto</button>
                    <MenuPopup className={styles.userDashboard}>
                        <button className="btn error" onClick={() => logOut()}>wyloguj</button>
                    </MenuPopup>
                </MenuContainer>
            ) : <button type="button" className="btn primary" onClick={() => login()}>zaloguj się</button>)}
        </>
    );
}

