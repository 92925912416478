export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type App = {
  __typename?: 'App';
  app_translations?: Maybe<Array<Maybe<AppTranslation>>>;
  client_id: Scalars['ID']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  modified_at?: Maybe<Scalars['DateTime']['output']>;
  owner_id: Scalars['ID']['output'];
  providers?: Maybe<Array<Maybe<AppProvider>>>;
  published_at?: Maybe<Scalars['DateTime']['output']>;
};

export type AppProvider = {
  __typename?: 'AppProvider';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified_at?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AppTranslation = {
  __typename?: 'AppTranslation';
  app_id: Scalars['ID']['output'];
  canonical_url?: Maybe<Scalars['String']['output']>;
  contact_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language_code?: Maybe<Scalars['String']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  modified_at?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  privacy_policy_url?: Maybe<Scalars['String']['output']>;
  terms_of_service_url?: Maybe<Scalars['String']['output']>;
};

export type AppsData = {
  __typename?: 'AppsData';
  apps?: Maybe<Array<Maybe<App>>>;
  pagination: Pagination;
};

export type AppsResponse = {
  __typename?: 'AppsResponse';
  code: Scalars['Int']['output'];
  data?: Maybe<AppsData>;
  status: Scalars['String']['output'];
};

export enum Enum_Content_Type {
  Article = 'article',
  Phone = 'phone',
  Service = 'service'
}

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  page_count: Scalars['Int']['output'];
  page_size: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  apps: AppsResponse;
  searchResults: SearchResults;
  users: UsersResponse;
};


export type QueryAppsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchResultsArgs = {
  query: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};

export type Search = {
  __typename?: 'Search';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Enum_Content_Type;
};

export type SearchResults = {
  __typename?: 'SearchResults';
  hits?: Maybe<Array<Maybe<Search>>>;
};

export type User = {
  __typename?: 'User';
  avatar_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified_at?: Maybe<Scalars['DateTime']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  published_at?: Maybe<Scalars['DateTime']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
  role_name?: Maybe<Scalars['String']['output']>;
};

export type UsersData = {
  __typename?: 'UsersData';
  pagination: Pagination;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  code: Scalars['Int']['output'];
  data?: Maybe<UsersData>;
  status: Scalars['String']['output'];
};
