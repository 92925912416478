import * as Types from './api-gateway.service.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortOrder?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fields?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', code: number, status: string, data?: { __typename?: 'UsersData', users?: Array<{ __typename?: 'User', id: string, email?: string | null, phone_number?: string | null, role_id?: number | null, role_name?: string | null, avatar_url?: string | null, created_at?: any | null, modified_at?: any | null, published_at?: any | null, deleted_at?: any | null } | null> | null, pagination: { __typename?: 'Pagination', page: number, page_size: number, page_count: number, total: number } } | null } };


export const UsersDocument = gql`
    query users($page: Int = 1, $limit: Int = 12, $sortBy: String = "created_at", $sortOrder: String = "desc", $fields: String = "id,email,phone_number,role_id,role_name,avatar_url,created_at,modified_at,published_at,deleted_at") {
  users(
    page: $page
    limit: $limit
    sortBy: $sortBy
    sortOrder: $sortOrder
    fields: $fields
  ) @rest(type: "UsersResponse", path: "/user?page={args.page}&limit={args.limit}&sort_by={args.sortBy}&sort_order={args.sortOrder}&fields={args.fields}") {
    code
    status
    data {
      users {
        id
        email
        phone_number
        role_id
        role_name
        avatar_url
        created_at
        modified_at
        published_at
        deleted_at
      }
      pagination {
        page
        page_size
        page_count
        total
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;