import * as Types from './api-gateway.service.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortOrder?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fields?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type AppsQuery = { __typename?: 'Query', apps: { __typename?: 'AppsResponse', code: number, status: string, data?: { __typename?: 'AppsData', apps?: Array<{ __typename?: 'App', id: string, created_at?: any | null, modified_at?: any | null, published_at?: any | null, deleted_at?: any | null, owner_id: string, client_id: string, app_translations?: Array<{ __typename?: 'AppTranslation', id: string, created_at?: any | null, modified_at?: any | null, app_id: string, language_code?: string | null, name?: string | null, description?: string | null, logo_url?: string | null, canonical_url?: string | null, privacy_policy_url?: string | null, contact_url?: string | null, terms_of_service_url?: string | null } | null> | null, providers?: Array<{ __typename?: 'AppProvider', id: string, created_at?: any | null, modified_at?: any | null, name?: string | null, description?: string | null } | null> | null } | null> | null, pagination: { __typename?: 'Pagination', page: number, page_size: number, page_count: number, total: number } } | null } };


export const AppsDocument = gql`
    query apps($page: Int = 1, $limit: Int = 12, $sortBy: String = "created_at", $sortOrder: String = "desc", $fields: String = "id,created_at,modified_at,published_at,deleted_at,owner_id,client_id") {
  apps(
    page: $page
    limit: $limit
    sortBy: $sortBy
    sortOrder: $sortOrder
    fields: $fields
  ) @rest(type: "AppsResponse", path: "/app?page={args.page}&limit={args.limit}&sort_by={args.sortBy}&sort_order={args.sortOrder}&fields={args.fields}") {
    code
    status
    data {
      apps {
        id
        created_at
        modified_at
        published_at
        deleted_at
        owner_id
        client_id
        app_translations {
          id
          created_at
          modified_at
          app_id
          language_code
          name
          description
          logo_url
          canonical_url
          privacy_policy_url
          contact_url
          terms_of_service_url
        }
        providers {
          id
          created_at
          modified_at
          name
          description
        }
      }
      pagination {
        page
        page_size
        page_count
        total
      }
    }
  }
}
    `;

/**
 * __useAppsQuery__
 *
 * To run a query within a React component, call `useAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useAppsQuery(baseOptions?: Apollo.QueryHookOptions<AppsQuery, AppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppsQuery, AppsQueryVariables>(AppsDocument, options);
      }
export function useAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppsQuery, AppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppsQuery, AppsQueryVariables>(AppsDocument, options);
        }
export type AppsQueryHookResult = ReturnType<typeof useAppsQuery>;
export type AppsLazyQueryHookResult = ReturnType<typeof useAppsLazyQuery>;
export type AppsQueryResult = Apollo.QueryResult<AppsQuery, AppsQueryVariables>;