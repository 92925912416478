export const createSlugForType = (type: string, id?: string): string => {
  switch (type) {
    case 'article':
    case 'ARTICLE':
      return `/a/${id}`;
    case 'tag':
    case 'TAG':
      return `/t/${id}`;
    case 'service':
    case 'SERVICE':
      return `/s/${id}`;
    case 'phone':
    case 'PHONE':
      return '/p';
    default:
      return '';
  }
};
